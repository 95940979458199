/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  font-family: "Raleway", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  max-width: 960px;
  margin: 0 auto;
}

.image-element {
  max-width: 100%;
}

.container-image {
  width: 33%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.height-maxed {
  max-height: 900px;
}

.thumbnail {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}

thumbnail-title {
  text-align: center;
}

.displayed {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
